<div class="m-card">
    <div class="m-card__wrapper">
        <div class="m-card__header"
            [style]="card.headerStyles">

            <div class="m-card__header-items">
                <div class="m-card__header-item">{{card.start }}</div>
                <div class="m-card__header-item">{{card.finish}}</div>
            </div>
        </div>
        <div class="m-card__body">
            <div class="m-card__main">{{card.main}}</div>
            <div class="m-card__list">
                <ng-container *ngFor="let item of card.items | paginate: configPagination">
                    <div *ngIf="item.value && item.value.length" class="m-card__item">
                        <div class="m-card__item-title" [translate]="item.title"></div>
                        <div class="m-card__item-value">
                            <div class="m-card__item-value-content" [style]="item?.valueStyles">
                                {{item.value | translate}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>
