import { Device } from '../../../app-shared-elements/_interfaces/Device';

export class InitMobileDevices {
    static readonly type = '[MobileDevices] InitMobileDevices';
}

export class UpdateMobileDevices {
    static readonly type = '[MobileDevices] UpdateMobileDevices';
    constructor(public devices: Device[]) {}
}

export class DeleteMobileDevice {
    static readonly type = '[MobileDevices] DeleteMobileDevice';
    constructor(public id: string) {}
}
