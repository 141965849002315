<div class="nav">
    <div class="nav__wrapper">

        <div class="nav__content">
            <div class="nav__burger"
                 [class.open]="toggleBurger"
                 appClickOutside
                 [isActiveClickOutside]="toggleBurger"
                 (clickOutside)="toggleBurger = false"
                 (click)="toggleBurger = !toggleBurger">

                <svg width="38" height="38" viewBox="0 0 100 100">
                    <path class="line line1"
                          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
                    <path class="line line2" d="M 20,50 H 80"/>
                    <path class="line line3"
                          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
                </svg>

                @if (toggleBurger) {
                    <div class="nav__settings">
                        <div class="nav__settings-list">

                            @for (item of mobileNavSettings$ | async; track item.name) {
                                <div class="nav__settings-item"
                                     (click)="itemEvent(item)">

                                    @if (item?.preIcons?.length) {
                                        <div class="nav__settings-pre-icons">

                                            @for (icon of item.preIcons; track icon.cellNames) {
                                                <div class="nav__settings-pre-icons-item" (click)="iconEvent(icon)">

                                                    <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                                                </div>

                                            }

                                        </div>
                                    }

                                    <div class="nav__settings-item-title">{{ item.title | translate }}</div>

                                    @if (item?.postIcons?.length) {
                                    <div class="nav__settings-pre-icons nav__settings-pre-icons--post">

                                        @for (icon of item.postIcons; track icon.cellNames) {
                                            <div *ngIf="icon.path"
                                                 class="nav__settings-pre-icons-item nav__settings-pre-icons-item--post"
                                                 (click)="iconEvent(icon)">
                                                <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>
                                            </div>

                                        }

                                    </div>
                                    }

                                </div>
                            }

                        </div>

                    </div>
                }
            </div>
            <div class="nav__alert">
                <app-error-alert [platform]="platformEnum.mobile"></app-error-alert>
            </div>

        </div>
    </div>
</div>
