import { Component, OnInit } from '@angular/core';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { Observable, Subject } from 'rxjs';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';
import { Store } from '@ngxs/store';
import { SocketService } from '../../../app-shared-elements/_services/socket.service';
import { PlatformEnum } from '../../../app-shared-elements/_enums/platform.enum';
import { SignalService } from '../../../app-shared-elements/_services/signal.service';
import { takeUntil } from 'rxjs/operators';
import { MobileNavState } from '../../../mobile/_store/states/mobile-nav.state';
import { MobileNavSettingsIconActions, MobileNavSettingsItemInterface } from '../_intefaces/mobile-nav-settings.interface';
import { InitMobileNavSettings } from '../../../mobile/_store/actions/mobile-nav.actions';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { MobileNavService } from '../_services/mobile-nav.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mobile-nav',
    templateUrl: './mobile-nav.component.html',
    styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent implements OnInit {
    user$: Observable<User> = this.store.select(UserState.getUser);
    mobileNavSettings$: Observable<MobileNavSettingsItemInterface[]> = this.store.select(MobileNavState.getMobileNavSettingList);

    toggleBurger = false;
    platformEnum = PlatformEnum;
    private user: User;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private socketService: SocketService,
        public signalService: SignalService,
        private store: Store,
        private mobileNavService: MobileNavService,
        private router: Router,
    ) {
        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            this.user = user;
            this.store.dispatch(new InitMobileNavSettings(user));
        });
    }

    ngOnInit(): void {}

    private logout(): void {
        this.socketService.logoutSubscribe.emit();
    }

    async iconEvent(icon: IconInterface<MobileNavSettingsIconActions>): Promise<void> {
        switch (icon.action) {
            case MobileNavSettingsIconActions.fullScreen:
                this.mobileNavService.fullScreen();
                break;
            case MobileNavSettingsIconActions.toggleSound:
                await this.signalService.updateSignal();
                this.store.dispatch(new InitMobileNavSettings(this.user));

                break;
        }
    }

    itemEvent(item: MobileNavSettingsItemInterface): void {
        if (!item.action) {
            return;
        }

        switch (item.action) {
            case MobileNavSettingsIconActions.moveToDevices:
                this.router.navigate(['/m/devices']);
                break;
            case MobileNavSettingsIconActions.moveToEvents:
                this.router.navigate(['/m/events']);
                break;
            case MobileNavSettingsIconActions.logout:
                this.logout();
                break;
        }
    }
}
