import { Injectable } from '@angular/core';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { AcknPropertyInterface } from 'src/app/app-shared-elements/_interfaces/acknowldge-property.inteface';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { LuxonDatePipe } from 'src/app/app-shared-elements/_pipes/luxon-date.pipe';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { TechnologicLogRowInterface } from 'src/app/journals/_interfaces/technologic-log-row.interface';
import { MobileCardItemInterface } from '../_interfaces/mobile-card.interface';

@Injectable({
    providedIn: 'root',
})
export class MobileEventsService {
    constructor(private luxonDatePipe: LuxonDatePipe) {}

    getEventCardItems(
        row: TechnologicLogRowInterface,
        properties: { title: string; prop: string }[],
        user: User,
        ln: LangEnum,
    ): MobileCardItemInterface[] {
        return properties.map((p) => {
            if (p.prop === 'ackn') {
                return {
                    title: p.title,
                    valueStyles:
                        row[p.prop].isAcknowledgeable && !row[p.prop].acknowledgedByUser && !row[p.prop].ts ? 'color: #FF6161' : null,
                    value: this.parsePropAckn(row[p.prop], user, ln),
                };
            }

            if (p.prop === 'type') {
                return {
                    title: p.title,
                    value: row[p.prop],
                    valueStyles: this.getValueStylesForType(row),
                };
            }

            return {
                title: p.title,
                value: row[p.prop],
            };
        });
    }

    private getValueStylesForType(row: TechnologicLogRowInterface): any {
        const skelet = {
            borderRadius: '18px',
            padding: '0 10px',
            height: '24px',
            'line-height': '24px',
        };

        switch (row.alarmType) {
            case AlarmTypeEnum.alarm:
                return {
                    ...skelet,
                    backgroundColor: '#573D40',
                    color: '#FF6161',
                };
            case AlarmTypeEnum.attention:
                return {
                    ...skelet,
                    backgroundColor: '#5B513B',
                    color: '#FFA23E',
                };
        }
    }

    private parsePropAckn(ackn: AcknPropertyInterface, user: User, ln: LangEnum): string {
        if (ackn.isAcknowledgeable && !ackn.acknowledgedByUser && !ackn.ts) {
            return 'filters.mailingUsersFilter.wait';
        }

        if (!ackn || !ackn.ts) {
            return null;
        }

        if (ackn.acknowledgedByUser) {
            return `${this.luxonDatePipe.transform(ackn.ts, user.dateFormat, user.dateTimeZone, ln)} <${ackn.acknowledgedByUser}>`;
        }

        if (ackn.ts) {
            return `${this.luxonDatePipe.transform(ackn.ts, user.dateFormat, user.dateTimeZone, ln)}`;
        }

        return null;
    }

    getHeaderCardStyle(row: TechnologicLogRowInterface): any {
        if (row.tsCleared.time) {
            return;
        }

        const skelet = {
            boxSizing: 'border-box',
        };

        switch (row.alarmType) {
            case AlarmTypeEnum.alarm:
                return {
                    ...skelet,
                    backgroundColor: '#49373B',
                    'border-bottom': '1px solid #FF6161',
                };
            case AlarmTypeEnum.attention:
                return {
                    ...skelet,
                    backgroundColor: 'rgba(86, 78, 53, 0.8)',
                    'border-bottom': '1px solid #F5A623',
                };
        }
    }
}
