import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {MobileNavSettingsItemInterface} from '../../../app-template/mobile/_intefaces/mobile-nav-settings.interface';
import {MobileNavService} from '../../../app-template/mobile/_services/mobile-nav.service';
import {InitMobileNavSettings} from '../actions/mobile-nav.actions';

export interface MobileNavModel {
    settingList: MobileNavSettingsItemInterface[];
}

const MOBILE_NAV_TOKEN = new StateToken<MobileNavModel>('mobileNavSettngsState');

@State<MobileNavModel>({
    name: MOBILE_NAV_TOKEN,
    defaults: {
        settingList: []
    }
})
@Injectable()
export class MobileNavState {
    constructor(
        private mobileNavService: MobileNavService
    ) {

    }


    @Selector()
    static getMobileNavSettingList(state: MobileNavModel): MobileNavSettingsItemInterface[] {
        return state.settingList;
    }


    @Action(InitMobileNavSettings)
    initMobileNavSettings(ctx: StateContext<MobileNavModel>, payload: InitMobileNavSettings): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            settingList: this.mobileNavService.getMobileNavListSetting(payload.user)
        });
    }
}
