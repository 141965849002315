import {NgModule} from '@angular/core';
import {MobileSharedModule} from './mobile-shared.module';
import {CommonModule} from '@angular/common';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MobileSharedModule
    ]
})
export class MobileModule {
}
