import {IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

export interface MobileNavSettingsItemInterface {
    title: string;
    preIcons: IconInterface<MobileNavSettingsIconActions>[];
    postIcons: IconInterface<MobileNavSettingsIconActions>[];
    action?: MobileNavSettingsIconActions; // only if we will haven`t action in pre and post icon actions!!!!!!!!!!
    name: MobileNavSettingsItemName;
}

export enum MobileNavSettingsIconActions {
    logout = 'logout',
    toggleSound = 'toggleSound',
    fullScreen = 'fullScreen',
    moveToDevices = 'moveToDevices',
    moveToEvents = 'moveToEvents'
}

export enum MobileNavSettingsItemName {
    devices = 'devices',
    events = 'events',
    profile = 'profile',
    logout = 'logout'
}
