import { Injectable } from '@angular/core';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import {
    MobileNavSettingsIconActions,
    MobileNavSettingsItemInterface,
    MobileNavSettingsItemName,
} from '../_intefaces/mobile-nav-settings.interface';
import { Platform } from '@angular/cdk/platform';

@Injectable({
    providedIn: 'root',
})
export class MobileNavService {
    private mobileNavSettings: MobileNavSettingsItemInterface[] = [
        {
            title: '',
            preIcons: [],
            postIcons: [
                {
                    path: './assets/design/icons/mobile/sound-on.svg',
                    cellNames: [],
                    action: MobileNavSettingsIconActions.toggleSound,
                },
                {
                    path: './assets/design/icons/mobile/full-screen.svg',
                    cellNames: [],
                    action: MobileNavSettingsIconActions.fullScreen,
                },
            ],
            name: MobileNavSettingsItemName.profile,
        },
        {
            title: 'mobile.nav.settings.devices',
            preIcons: [
                {
                    path: './assets/design/icons/mobile/device-page.svg',
                    cellNames: [],
                },
            ],
            postIcons: [],
            action: MobileNavSettingsIconActions.moveToDevices,
            name: MobileNavSettingsItemName.devices,
        },
        {
            title: 'mobile.nav.settings.events',
            preIcons: [
                {
                    path: './assets/design/icons/mobile/events-page.svg',
                    cellNames: [],
                },
            ],
            postIcons: [],
            action: MobileNavSettingsIconActions.moveToEvents,
            name: MobileNavSettingsItemName.events,
        },
        {
            title: 'mobile.nav.settings.logout',
            preIcons: [
                {
                    path: './assets/design/icons/mobile/logout.svg',
                    cellNames: [],
                    action: MobileNavSettingsIconActions.logout,
                },
            ],
            postIcons: [],
            action: MobileNavSettingsIconActions.logout,
            name: MobileNavSettingsItemName.logout,
        },
    ];

    private full: boolean;

    constructor(private platform: Platform) {}

    getMobileNavListSetting(user: User): MobileNavSettingsItemInterface[] {
        return this.mobileNavSettings.map((item) => {
            switch (item.name) {
                case MobileNavSettingsItemName.profile:
                    if (!user) {
                        return;
                    }
                    const indexAt = user?.login.indexOf('@');
                    return {
                        ...item,
                        title: user.name ?? user.login.slice(0, indexAt + 1),
                        postIcons: item.postIcons.map((i) => {
                            if (i.action === MobileNavSettingsIconActions.fullScreen) {
                                return {
                                    ...i,
                                    path: this.platform.IOS ? '' : './assets/design/icons/mobile/full-screen.svg',
                                };
                            }
                            if (i.action === MobileNavSettingsIconActions.toggleSound) {
                                return {
                                    ...i,
                                    path: !user.isAlarmSound
                                        ? './assets/design/icons/mobile/sound-off.svg'
                                        : './assets/design/icons/mobile/sound-on.svg',
                                };
                            }
                            return i;
                        }),
                    };
                // case MobileNavSettingsItemName.logout:
                default:
                    return item;
            }
        });
    }

    async fullScreen(): Promise<void> {
        this.full = !this.full;
        const elem = document.querySelector('.mobile__container-full-screen');
        this.full ? await elem.requestFullscreen() : await document.exitFullscreen();
    }
}
