import { MobileDevicesInterface } from '../../_interfaces/devices.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GroupsState } from '../../../groups/_store/states/groups.state';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { DeleteMobileDevice, InitMobileDevices, UpdateMobileDevices } from '../actions/mobile-devices.actions';
import { Group } from '../../../app-shared-elements/_interfaces/Group';
import { Variable } from '../../../app-shared-elements/_interfaces/Variable';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';

export interface MobileDevicesStateModel {
    mobileDevicesArray: MobileDevicesInterface[];
}

const MOBILE_DEVICES_TOKEN = new StateToken<MobileDevicesStateModel>('mobileDevices');

@State<MobileDevicesStateModel>({
    name: MOBILE_DEVICES_TOKEN,
    defaults: {
        mobileDevicesArray: [],
    },
})
@Injectable()
export class MobileDevicesState {
    constructor(private store: Store) {}

    @Selector()
    static getMobileDevicesArray(state: MobileDevicesStateModel): MobileDevicesInterface[] {
        return state.mobileDevicesArray.sort((a, b) => (a.registrator.name > b.registrator.name ? 1 : -1));
    }

    @Action(InitMobileDevices)
    async initMobileDevices(ctx: StateContext<MobileDevicesStateModel>): Promise<void> {
        const state = ctx.getState();
        const groups: Group[] = this.store.selectSnapshot(GroupsState.getGroups);
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const mobileDevicesArray: MobileDevicesInterface[] = [];

        devices.forEach((device) => {
            if (device.type === DeviceTypeEnum.registrator || device.creationType !== CreationType.MNEMONIC) {
                mobileDevicesArray.push({
                    registrator: {
                        ...device,
                        name: device.name ?? device.defaultName,
                    },

                    sensors: devices
                        .filter((d) => (d.creationType === CreationType.VIRTUAL ? d.id === device.id && d.variables.length : d.registratorId === device.id))
                        .filter((d) => d.type !== DeviceTypeEnum.coordinator)
                        .map((sensor) => {
                            const currentGroup: Group = groups.find((g) => g.deviceId === sensor.id);
                            if (!currentGroup) {
                                return;
                            }

                            const currentGroupVariables: VariableGroupSettings[] = currentGroup.variableGroupSettings.filter((group) => group.showOnGroup);
                            if (!currentGroupVariables) {
                                return;
                            }

                            return {
                                ...sensor,
                                variableGroup: currentGroupVariables
                                    .map((item) => {
                                        const currentVariable: Variable = sensor.variables.find((v) => v.id === item.variableId);

                                        if (!currentVariable) {
                                            return;
                                        }

                                        return {
                                            status: currentVariable.status,
                                            currentName: currentVariable.customName ?? currentVariable.name,
                                            currentValue: currentVariable.currentValue,
                                            unit: currentVariable.unitName,
                                            variable: currentVariable,
                                            isConnect: currentVariable.isConnect,
                                            variableId: currentVariable.id,
                                        };
                                    })
                                    .filter((f) => !!f)
                                    .sort((a, b) => (a.variable.name > b.variable.name ? 1 : -1)),
                                name: sensor.name ?? sensor.defaultName,
                            };
                        })
                        .filter((s) => !!s),
                });
            }
        });

        ctx.setState({
            ...state,
            mobileDevicesArray,
        });
    }

    @Action(UpdateMobileDevices)
    async updateMobileDevices(ctx: StateContext<MobileDevicesStateModel>, payload: UpdateMobileDevices): Promise<void> {
        const state = ctx.getState();
        const devices: any = payload.devices;

        ctx.setState({
            ...state,
            mobileDevicesArray: state.mobileDevicesArray
                .map((d) => {
                    const currentRegistrator = devices?.find((device) => device.id === d?.registrator?.id);

                    if (!currentRegistrator) {
                        return;
                    }

                    return {
                        ...d,
                        registrator: {
                            ...currentRegistrator,
                            name: currentRegistrator?.name,
                        },
                        sensors: d.sensors
                            .map((sensor) => {
                                const currentSensor = devices.find((device) => device.id === sensor.id);
                                if (!currentSensor) {
                                    return;
                                }

                                return (sensor = {
                                    ...currentSensor,
                                    variableGroup: sensor.variableGroup
                                        .map((item) => {
                                            const currentVariable: Variable = currentSensor.variables.find((v) => v.id === item.variableId);
                                            if (!currentVariable) {
                                                return;
                                            }

                                            return {
                                                ...item,
                                                variable: currentVariable,
                                                currentName: currentVariable.customName ?? currentVariable.name,
                                                isConnect: currentVariable.isConnect,
                                                unit: currentVariable.unitName,
                                                currentValue: currentVariable.currentValue,
                                                status: currentVariable.status,
                                            };
                                        })
                                        .sort((a, b) => (a.variable.name > b.variable.name ? 1 : -1)),
                                    name: currentSensor.name,
                                });
                            })
                            .filter((f) => !!f),
                    };
                })
                .filter((f) => !!f),
        });
    }

    @Action(DeleteMobileDevice)
    deleteMobileDevice(ctx: StateContext<MobileDevicesStateModel>, payload: DeleteMobileDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mobileDevicesArray: state.mobileDevicesArray.filter((d) => d.registrator.id !== payload.id),
        });
    }
}
