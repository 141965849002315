import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParamsFilterForClient, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';

@Component({
    selector: 'app-mobile-filter',
    templateUrl: './mobile-filter.component.html',
    styleUrls: ['./mobile-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFilterComponent implements OnInit {
    @Input() filters: ParamsFilterForClient[] = [];

    @Output() changeFilterEvent: EventEmitter<ParamsFilterForClient[]> = new EventEmitter<ParamsFilterForClient[]>();

    private changedFilters: ParamsFilterForClient[] = [];

    paramsFilterTypeEnum = ParamsFilterTypeEnum;

    constructor() {}

    ngOnInit(): void {}

    changeFilter(filter: ParamsFilterForClient): void {
        this.filters = this.filters.map((f) => {
            if (f.title === filter.title) {
                return {
                    ...filter,
                    value: !filter.value,
                };
            }
            return f;
        });
    }

    close(): void {
        this.changeFilterEvent.emit(this.filters);
    }
}
