import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MobileComponent} from '../app-template/mobile/mobile.component';
import {MobileGuard} from '../app-shared-elements/_guards/mobile.guard';
import {UserAuthGuardService} from '../auth/user-auth-guard.service';
import {MobileDevicesComponent} from './mobile-devices/mobile-devices.component';
import {MobileEventsComponent} from './mobile-events/mobile-events.component';

const mobileRoutes: Routes = [
    {
        path: '',
        component: MobileComponent,
        canActivate: [UserAuthGuardService, MobileGuard],
        children: [
            {
                path: 'devices',
                data: { title: 'Devices' },
                component: MobileDevicesComponent,
            },
            {
                path: 'events',
                data: { title: 'events' },
                component: MobileEventsComponent,
            }
        ]
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(mobileRoutes),
    ],
})
export class MobileRoutingModule {}
